var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "about__section-title" }, [
          _vm._v("Для администратора"),
        ]),
        _c("h2", { staticClass: "about__section-subtitle" }, [
          _vm._v(" Приветствуем вас в электронной очереди! "),
        ]),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Для начала работы выберите необходимый терминал в выпадающем списку в верхнем правом углу. "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/1.png"),
            alt: "Для начала работы выберите необходимый терминал в выпадающем списку в\n      верхнем правом углу.",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Добавьте общую квоту терминала на день. Общая квота терминала на день — это правило по количеству машин в день, которое может принять терминал в конкретный день. "
          ),
        ]),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Выберите дату и укажите количество машин. Нажмите кнопку «Добавить и закрыть». "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/2.png"),
            alt: "Выберите дату и укажите количество машин. Нажмите кнопку «Добавить и\n      закрыть».",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Квота на день создана! Теперь уточните квоту и укажите ограничения по культурам. Нажмите значок + в нижнем правом углу. "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/3.png"),
            alt: "Нажмите значок + в нижнем правом углу.",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Раскройте выпадающий список, выберите культуру и укажите необходимое количество количество машин. "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/4.png"),
            alt: "Раскройте выпадающий список, выберите культуру и укажите необходимое количество количество машин.",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Если вам необходимо создать общую квоту на группу культур, нажмите на значок + в нижнем правом углу. Затем выберите первую культуру, нажмите зеленый значок +, добавьте вторую культуру и укажите число авто, которое вы хотите запланировать для этих двух культур. Нажмите кнопку «Добавить и закрыть». "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/5.png"),
            alt: "Добавление группы культур",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v("Квота на день запланирована!"),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/6.png"),
            alt: "Квота на день запланирована!",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " Если вам необходимо добавить новую культуру для терминала, перейдите во вкладку Управление в верхнем правом углу. Нажмите бургер меню, расположенный в левой части экрана. "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/7.png"),
            alt: "Добавление новой культуры к терминалу",
          },
        }),
        _c("p", { staticClass: "about__section-text" }, [
          _vm._v(
            " В правой части экрана нажмите кнопку …, выберите пункт «Добавить культуру». Введите название культуры. Нажмите кнопку «Добавить» Если вы не планируете использовать культуру для терминала вы можете ее удалить. "
          ),
        ]),
        _c("img", {
          staticClass: "about__section-img",
          attrs: {
            src: require("@/assets/img/about/8.png"),
            alt: "Общий вид таблицы культур",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }