<template>
  <div class="about">
    <div class="container">
      <h1 class="about__section-title">Для администратора</h1>

      <h2 class="about__section-subtitle">
        Приветствуем вас в электронной очереди!
      </h2>

      <p class="about__section-text">
        Для начала работы выберите необходимый терминал в выпадающем списку в
        верхнем правом углу.
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/1.png"
        alt="Для начала работы выберите необходимый терминал в выпадающем списку в
        верхнем правом углу."
      />

      <p class="about__section-text">
        Добавьте общую квоту терминала на день. Общая квота терминала на день —
        это правило по количеству машин в день, которое может принять терминал в
        конкретный день.
      </p>

      <p class="about__section-text">
        Выберите дату и укажите количество машин. Нажмите кнопку «Добавить и
        закрыть».
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/2.png"
        alt="Выберите дату и укажите количество машин. Нажмите кнопку «Добавить и
        закрыть»."
      />

      <p class="about__section-text">
        Квота на день создана! Теперь уточните квоту и укажите ограничения по
        культурам. Нажмите значок + в нижнем правом углу.
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/3.png"
        alt="Нажмите значок + в нижнем правом углу."
      />

      <p class="about__section-text">
        Раскройте выпадающий список, выберите культуру и укажите необходимое
        количество количество машин.
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/4.png"
        alt="Раскройте выпадающий список, выберите культуру и укажите необходимое количество количество машин."
      />

      <p class="about__section-text">
        Если вам необходимо создать общую квоту на группу культур, нажмите на
        значок + в нижнем правом углу. Затем выберите первую культуру, нажмите
        зеленый значок +, добавьте вторую культуру и укажите число авто, которое
        вы хотите запланировать для этих двух культур. Нажмите кнопку «Добавить
        и закрыть».
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/5.png"
        alt="Добавление группы культур"
      />

      <p class="about__section-text">Квота на день запланирована!</p>

      <img
        class="about__section-img"
        src="@/assets/img/about/6.png"
        alt="Квота на день запланирована!"
      />

      <p class="about__section-text">
        Если вам необходимо добавить новую культуру для терминала, перейдите во
        вкладку Управление в верхнем правом углу. Нажмите бургер меню,
        расположенный в левой части экрана.
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/7.png"
        alt="Добавление новой культуры к терминалу"
      />

      <p class="about__section-text">
        В правой части экрана нажмите кнопку …, выберите пункт «Добавить
        культуру». Введите название культуры. Нажмите кнопку «Добавить» Если вы
        не планируете использовать культуру для терминала вы можете ее удалить.
      </p>

      <img
        class="about__section-img"
        src="@/assets/img/about/8.png"
        alt="Общий вид таблицы культур"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="sass">
.about
  padding: 40px 0

  &__section-title
    font-size: 2em
    margin: 0 0 26px
    font-weight: bold

  &__section-subtitle
    font-size: 1.6em
    margin: 0 0 20px
    font-weight: bold

  &__section-text
    font-size: 1.2em
    margin: 1em 0

  &__section-img
    width: 100%
    max-width: 100%
    margin: 1em 0
</style>
